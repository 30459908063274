import { getGlobal, setGlobal, apiPost, apiGet, notify, confirm } from 'launchpad'
import { startLoad, stopLoad, getBrowser } from 'launchpad/helpers'
import { getPDFOptions } from './PDFOptions'
export { PDFOptions, getPDFOptions } from './PDFOptions'
import { getPhotos } from './SelectPhotos'
export { getPhotos, PhotoSelector } from './SelectPhotos'
export { getProject, ProjectSelector } from './SelectProject'
import { getProject } from './SelectProject'
import { loadUser } from '../UserLogin'
import uuid from 'uuid/v1'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
export { default as generateKMZ } from './kmz'
import requireUpgrade from './requireUpgrade'
import debounce from 'lodash/debounce'
import { getProjects, getUpdatingProjects, getLimit } from './sync'
export { getUpdatingProjects } from './sync'

const projectMaxed = 'Sorry, your account is maxed! Please delete unused projects or upgrade your account'


export const exportPhotos = async (project, options = {}) => {
  if(requireUpgrade()) return
  const pname = project.name.replace(/ /g, '_')
  const zip = JSZip()
  let images = project.images
  const pendingPhotos = images.filter(x => !x.url)

  // for performance testing
  // images = images.concat(images).concat(images).concat(images).concat(images).concat(images)
  //   .concat(images).concat(images).concat(images).concat(images).concat(images).concat(images).concat(images)

  let progress = 0
  const ts = startLoad()

  let batch = []
  let i = 0
  if(images.length > pendingPhotos.length) {
    try {
      for(let image of images.filter(x => !!x.url)) {
        i++
        // if(!image.url) continue
        if(batch.length >= 8) {
          await Promise.all(batch)
          batch = []
        }
        batch.push(new Promise((resolve, reject) => {
          fetch(image.url).then(async res => {
            try {
              const blob = await res.blob()
              i = i + 1
              const num = i <= 9999 ? ('000'+i).slice(-4) : i
              const ts = image.timestring ? image.timestring.replace(/ /g, '_').replace(/\/|:/g, '-') : 'unknown_date'
              zip.file(`${num}_${ts}.${image.url.split('.').pop()}`, blob)
              progress += 100 / images.length
              if(options.onProgress) options.onProgress(progress)
              resolve()
            } catch(e) {
              console.error(e)
              reject(e)
            }
          })
        })
        )
      }
      await Promise.all(batch)

      const blob = await zip.generateAsync({ type: 'blob' })
      saveAs(blob, `${pname}_${new Date().toJSON().slice(0,10)}.zip`)
    } catch(e) {
      notify('Sorry, something seems to have gone wrong downloading these photos. Please try again later.', { type: 'warning' })
    }
  } else {
    notify('This project has no photos available for download!')
  }

  if(pendingPhotos.length) {
    notify(`${pendingPhotos.length} unsynced photos could not be downloaded`, { duration: 10, type: 'warning' })
  }


  stopLoad(ts)
}



export const generatePDF = async (photos) => {
  if(requireUpgrade()) return

  const selected = (await getPhotos(photos)).map(x => {
    // format location for PDF generator
    let ret = { ...x }
    if(x.location && x.location.coordinates) ret.location = {
      lat: x.location.coordinates[1],
      long: x.location.coordinates[0],
      heading: x.heading
    }
    ret.display_date = new Date(x.created).toLocaleString()
    if(!x.location) ret.location = { lat: 0, long: 0, heading: 0 }
    return ret
  })
  setGlobal({ selectedPDFPhotos: selected })
  const options = await getPDFOptions()

  if(!selected.length) {
    notify('No photos selected')
    return
  }

  return new Promise(async resolve => {
    const timestamp = new Date().getTime()
    const { socket, user } = getGlobal()

    let success = false

    const end = () => {
      setGlobal({ generatingPDF: false, mapGenerated: false })
      // socket.off(`pdf-failed-${timestamp}`, fail)
      // socket.off(`pdf-generated-${timestamp}`, complete)
      resolve()
    }

    const fail = (data) => {
      if(!success) {
        console.log(data)
        notify(`Sorry, we experienced an unknown error, please try again`, { type: 'error' })
        end()
      }
    }

    const complete = (data) => {
      success = true
      const a = document.createElement('a')
      a.setAttribute('href', `/api/v1${data.url}`)
      a.setAttribute('download', data.url.split('/pdfs/')[1])
      a.click()
      notify(`PDF successfully generated! Your download should begin shortly`, { type: 'success' })
      end()
    }

    setGlobal({ generatingPDF: true, mapGenerated: true })
    const data = {
      user: user.id,
      timestamp,
      images: selected,
      ...options
    }
    apiPost('/generate-pdf', data).then((data) => {
      if (data.error) {
        fail();
      } else {
        complete(data);  
      }
    })
    /*
      socket.emit('generate-pdf', data)
      socket.on(`pdf-generated-${timestamp}`, complete)
      socket.on(`pdf-failed-${timestamp}`, fail)
      setTimeout(fail, 30 * 1000)
    */
  })
}



const timestamp = () => (new Date).getTime()

// tools to get/set the root of the web app
let app = null
export const getApp = () => app
export const setApp = (a) => app = a

const uid = () => (getGlobal('user') || {}).id

// trigger a loading screen (with a required tag)
export const load = (tag) => {
  if(!tag) throw 'Using load/endLoad without an id (first argument) is not allowed'
  if(app){
    let loaders = app.state.loaders
    if(!loaders.includes(tag)) app.setState({ loaders: loaders.concat(tag) })
  }
}

// remove a loading trigger by its tag
export const endLoad = (tag) => {
  if(app){
    let loaders = app.state.loaders
    if(loaders.includes(tag)) app.setState({ loaders: loaders.filter(x => x != tag ) })
  }
}

// consistent behavior for async functions (loading / error handling)
const attempt = async fn => {
  const ts = timestamp()

  // trigger loading screen
  load(ts)

  let result
  try {
    result = await fn()
  } catch (err) {
    console.error(err)
    notify(`Sorry, something seems to have gone wrong, please try again`, { type: 'error' })
  }

  // end loading screen (even if it fails)
  endLoad(ts)
  return result
}




// push updates to connected devices
export const update = (type, object) => attempt(async () => {
  if(!object[`${type}Id`]) object[`${type}Id`] = uuid()
  await apiPost(type, object)
})

// update project
export const updateProject = async p => update('project', p)

// update image
export const updateImage = async i => update('image', i)


// move image to new project
export const moveImage = async () => {
  const project = await getProject()
  if(project) {
    const { activeImage } = actives()
    const oldP = activeImage.projectId
    await apiPost(`move-image/${activeImage.imageId}`, { project: project.id })
    setGlobal({ activeImageId: activeImage.imageId, activeProjectId: project.id })
  }
}


// duplicate image within project
export const copyImage = async () => {
  const { activeImage } = actives()
  const  newId = uuid()
  await apiPost(`copy-image/${activeImage.imageId}`, { newId })
  setGlobal({ activeImageId: newId, activeProjectId: activeImage.projectId })
}



// create project
export const createProject = name => attempt(async () => {
  const { user, projects, allProjects } = getGlobal()
  const limit = getLimit()
  if(limit.maxed) return notify(projectMaxed, { type: 'error' })
  await apiPost(`/create-project/${user.id}`, { project: { name, id: uuid(), updated: timestamp() }})
})

// delete an existing project
export const deleteProject = p => attempt(async () => {
  // console.log(p)
  // return
  await apiPost(`/delete-project/${uid()}/${p.id}`)
})

// restore an archived project
export const restoreProject = p => attempt(async () => {
  await apiPost(`/restore-project/${p.id}`)
})

// hard delete a project (wipe all images)
export const decimateProject = p => console.log(p) || attempt(async () => {
  await apiPost(`/decimate-project/${p.id}`)
})

// hard delete user account
export const deleteUserAccount = () => attempt(async () => {
  await apiPost(`/delete-my-account`)
})

export const deletePhoto = i => attempt(async () => {
  confirm('Are you sure you want to delete this photo?', async () => {
    await apiPost(`/delete-image/${uid()}/${i.imageId}`)
  })
})


// get active project/image
export const actives = () => {
  const { activeImageId, activeProjectId, activeCluster } = getGlobal()
  const projects = getProjects()
  const activeProject = projects && activeProjectId && projects.find(x => x.id == activeProjectId)
  // console.log(projects, activeProjectId)
  return {
    activeProject,
    activeImage: activeImageId && activeProject && activeProject.images.find(x => x.imageId == activeImageId),
    activeCluster
  }
}


// get google map bounds based on image locations
export const getBounds = (images, maps) => {
  if(!images.filter(x => x.latitude).length) {
    return new maps.LatLngBounds(
      { lat: 47.7511, lng: -120.7401 },
      { lat: 39.8283, lng: -71.5795 }
    )
  }
  var min = { lat: 90, lng: 180 }
  var max = { lat: -90, lng: -180 }
  images.map(i => {
    var l = { lat: i.latitude, long: i.longitude }
    if(l.lat && l.long){
      if(l.lat < min.lat){
        min.lat = l.lat
      }
      if(l.long < min.lng){
        min.lng = l.long
      }
      if(l.lat > max.lat){
        max.lat = l.lat
      }
      if(l.long > max.lng){
        max.lng = l.long
      }
    }
  })
  var xd = max.lat - min.lat
  var yd = max.lng - min.lng
  var xp = Math.max(xd / 3, .00005)
  var yp = Math.max(xd / 4, .00005)

  min.lat -= xp
  max.lat += xp
  min.lng -= yp
  max.lng += yp
  return new maps.LatLngBounds(min, max)
}



// Upload photos
let FI = null
export const uploadPhotos = async (p, options) => {
  options = options || {}
  const go = async (e) => {
    try {
      const files = []
      for(let file of e.target.files) files.push(file)
      const total = files.length
      let completed = 0
      let maxed = false
      const count = () => {
        completed += 1
        if(options.onProgress) options.onProgress(completed / total * 100)
      }

      const ts = startUpdatingProject(p)
      const limit = getLimit()
      if(limit.maxed) {
        notify(projectMaxed, { type: 'error' })
        stopUpdatingProject(ts)
        options.onProgress && options.onProgress(null)
        return
      }
      await Promise.all(files.map((photo, i) => {
        if(maxed) {
          count()
          return new Promise((resolve) => resolve())
        }
        return (async resolve => {
          let file = new FormData()
          const photoId = uuid()
          file.append('photo_'+photoId, photo, 'photo'+photoId+'.jpg')
          file.append('imageId', photoId)
          file.append('capture_method', 'import')
          file.append('source_type', 'desktop')
          file.append('source_platform', getBrowser())
          file.append('skip_caching', true)
          // console.time(`upload ${image.id}`)
          const res = await fetch('/api/v1/upload-project-image/' + `${getGlobal('user').id}/${p.id}`, { method:'POST', body:file })
          try {
            const j = await res.json()
            if(j.error) {
              notify(j.error, { type: 'error' })
              if(j.error_code == 'maxed') maxed = true
              console.log(j.error)
            }
          } catch(e) {
            console.log(e)
          }
          count()
          // console.timeEnd(`upload ${image.id}`)
        }
        )()
      }))
      updateProjectCaches(p)
      stopUpdatingProject(ts)
      setTimeout(() => options.onProgress && options.onProgress(null), 2000)
    } catch(e) {
      console.log(e)
    }
  }
  if(!FI){
    FI = document.createElement('input')
    FI.type = 'file'
    FI.id = 'zia-upload-input'
    FI.setAttribute('accept', 'image/*')
    FI.setAttribute('multiple', true)
    FI.setAttribute('style', 'display:none;')
    document.getElementsByTagName('body')[0].appendChild(FI)
  }
  FI.value = null
  const newFI = FI.cloneNode(true)
  FI.parentNode.replaceChild(newFI, FI)
  FI = newFI
  FI.addEventListener('change', e => go(e))
  FI.click()
}



export const startUpdatingProject = p => {
  let { projectUpdaters } = getGlobal()
  const ts = new Date().getTime()
  const u = { ...projectUpdaters, [ts]: p.id || p }
  setGlobal({ projectUpdaters: u })
  return ts
}

export const stopUpdatingProject = ts => {
  let projectUpdaters = { ...getGlobal('projectUpdaters') }
  delete projectUpdaters[ts]
  setGlobal({ projectUpdaters })
}

// export const getUpdatingProjects = () => {
//   const updaters = getGlobal('projectUpdaters') || {}
//   let list = []
//   Object.keys(updaters).forEach(x => {
//     if(!list.includes(updaters[x])) list.push(updaters[x])
//   })
//   return list
// }

export const updateProjectCaches = async p => {
  // const ts = Date.now()
  // load(ts)
  await apiPost(`update-caches/${p.id}`)
  // endLoad(ts)
}


export const rotateImage = async (img, dir) => {
  const { imageVersions } = getGlobal()
  const id = img.imageId
  await apiPost(`/rotate-project-image/${id}/${dir}`)
  setGlobal({ imageVersions: { ...imageVersions || {}, [id]: new Date().getTime() }})
}

export const rotateActiveImage = async dir => {
  const { activeImage } = actives()
  return rotateImage(activeImage, dir)
}

const getVersionedImage = (img, field) => {
  if(!img) return ''
  const { imageVersions } = getGlobal()
  let url = img ? img[field] : ''
  const v = imageVersions && imageVersions[img.imageId]
  if(v) {
    url += `?${v}`
  }
  return url
}

export const getImageUrl = img => getVersionedImage(img, 'url')
export const getThumbnailUrl = img => getVersionedImage(img, 'thumbnail')


export const sortProjects = (projects, type) => {
  const sort = type || getGlobal('project_sort') || 'age-down'
  projects = [ ...projects ]
  const sortDir = sort.split('-')[1] || 'down'
  const sortProp = sort.split('-')[0] || 'age'
  projects.sort((pA, pB) => {
    if(sortProp == 'age') {
      return pA._id > pB._id ? 1 : -1
    } else {
      return pA.name.toLowerCase().trim() > pB.name.toLowerCase().trim() ? 1 : -1
    }
  })
  if(sortDir == 'up') projects.reverse()
  return projects
}
