import React, { useEffect, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import supercluster from 'points-cluster'
import { getGlobal, setGlobal, notify } from 'launchpad'
import { actives, getBounds, updateImage } from './tools'
import PhotoDetails from './PhotoDetails'
import { Collapsible, Slider } from 'widgets'
import { useSetState } from 'launchpad/helpers'


const emptyMapState = {
  renderedProject: null,
  mappedProject:null,
  renderReady: null,
  map: null,
  maps: null,
  renderReady: null
}


const Marker = ({ children }) => <div className='marker'>{children}</div>
const ClusterMarker = ({ children }) => <div className='marker'>{children}</div>

export default function MapPanel({ projects }) {
  const [ state, setState ] = useSetState({
    ...emptyMapState,
    heading: 0,
    latitude: 0,
    longitude: 0,
    desc: null,
  })

  const { heading, latitude, longitude, desc } = state
  const { editingImage } = getGlobal()
  const { activeProject, activeImage } = actives()

  // check if there are any images with location data
  const loc = activeProject && activeProject.images.filter(x => x.latitude && x.longitude).length

  useEffect(() => {
    // reset state to match currently edited image location
    if(editingImage != state.editingImage) {
      const { latitude, longitude, heading } = editingImage
      setState({ latitude, longitude, heading })
    }
  }, [ editingImage ])


  const commitLocation = async () => {
    if(editingImage) {
      let update = false;
      [ 'latitude', 'longitude', 'heading' ].forEach(x => {
        if(state[x] != editingImage[x]) update = true
      })
      let data = { ...editingImage, latitude, longitude, heading }
      if(desc !== null) {
        update = true
        data.description = desc
      }
      if(update) await updateImage(data)
      setGlobal({ editingImage: null })
      notify('Image metadata successfully updated', { type: 'success' })
    }
  }

  const registerLoc = e => {
    setState({ latitude: e.lat, longitude: e.lng }, () => this.setLoc())
  }

  const mapProps = { heading, latitude, longitude, editingImage, registerLoc, activeImage }

  return <div className='map-preview'>

    <PhotoDetails onDesc={desc => setState({ desc })} onAccept={commitLocation} />
    <Collapsible open={!!activeImage && !!editingImage}>
      <div className='edit-panel'>
        <div>
          <p>Click the map to select a location</p>

          <div className='heading-selector'>
            <label>
              <span>{(latitude || 0).toFixed(4)}&deg;N, {(longitude || 0).toFixed(4)}&deg;W</span>
              <span>{heading}&deg;</span>
            </label>
            <div>
              <span>0&deg;</span>
              <Slider value={heading} onChange={(e, heading) => setState({ heading })} min={0} max={360}/>
              <span>360&deg;</span>
            </div>
          </div>
        </div>
        {/* <div className='buttons'>
          <div className='fa fa-times' onClick={() => setGlobal({editingImage: null})} />
          <div className='fa fa-check' onClick={commitLocation} />
        </div> */}
      </div>
    </Collapsible>
    {activeProject && (loc || editingImage)
      ?
      <div className='map'>
        <PhotoMap
          photos={activeProject ? activeProject.images : []}
          key={activeProject.id} {...mapProps}
        />
      </div>
      :
      (activeProject ?
        <p className='message'>No location data for this project</p> :
        <ProjectsMap
          projects={projects}
          {...mapProps} />
      )
    }
  </div>
}


export const PhotoMap = ({ photos, heading, latitude, longitude, editingImage,
  registerLoc, activeImage, mapType, showOrientation, onMove }) => {

  const [ state, setState ] = useSetState(emptyMapState)
  const { map, maps, renderReady, renderedProject, mappedProject } = state
  const [ clusters, setClusters ] = useState([])
  const [ currentMapType, setCurrentMapType ] = useState(mapType);
  const { activeCluster } = getGlobal()
  
  const pID = JSON.stringify(photos)

  const getPhotoByID = Object.fromEntries(
    photos.map(p => [p.imageId, p])
  )

  useEffect(() => {
    // reset map if active project changes
    if(!photos.length) {
      setState(emptyMapState)
    } else {
      setState({ renderedProject: pID, mappedProject: null })
      if(map) {        
        map.fitBounds(getBounds(photos, maps))
        // setState({map: null, maps: null, renderReady: null})
      }
    }

  }, [ pID, JSON.stringify(getGlobal('selectedPhotos')) ])

  useEffect(() => {
    // load map if render is marked as ready (see first side effect)
    if(renderReady) {
      if(renderedProject && maps && pID == renderReady && pID != mappedProject) {
        setState({ mappedProject: pID })
        map.fitBounds(getBounds(photos, maps))
      }
      if(onMove) {
        const m = () => {
          const c = map.getCenter()
          onMove({ lat: c.lat(), lng: c.lng(), zoom: map.zoom })
        }
        map.addListener('center_changed', m)
        map.addListener('zoom_changed', m)
      }
    }
  }, [ renderReady ])

  const handleMapChange = ({ center, zoom, bounds }) => {
    console.log(center, zoom, bounds)
    const markersData = photos
      .filter(x => !x.archived && (x.latitude || x.imageId == (editingImage && editingImage.imageId)))
      .map(photo => { return {id: photo.imageId, lat: photo.latitude, lng: photo.longitude}});
    const getClusters = supercluster(markersData, {
      minZoom: 0,
      maxZoom: 21,
      radius: 20,
    });
    if(map) {
      const clusters = getClusters({center, zoom, bounds});
      console.log(clusters);
      setClusters(clusters);
    }  
  } 

  return <GoogleMapReact
    key={pID}
    bootstrapURLKeys={{ key: 'AIzaSyByRREcA4y26ImKgyRCR3Nkb2wfBDrRx3I' }}
    defaultCenter={{ lat: 39.8283, lng: 98.5795 }}
    defaultZoom={12}
    options={maps => getMapOptions(maps, currentMapType)}
    yesIWantToUseGoogleMapApiInternals
    onGoogleApiLoaded={({ map, maps }) => setState({ map, maps, renderReady: pID })}
    onChange={handleMapChange}
    onMapTypeIdChange={(mt) => setCurrentMapType(mt)}
    onClick={registerLoc}
  >
    {clusters.map((item, key) => {
      if (item.numPoints === 1) {
        let photo = getPhotoByID[item.points[0].id];
        const active = activeImage && activeImage.imageId == item.points[0].id
        if(editingImage && editingImage.imageId == item.points[0].id) {
          photo = { ...photo, latitude, longitude, heading }
        }
        return (
          <Marker
            key={key}
            lat={photo.latitude}
            lng={photo.longitude}
          >
            <div className={`marker-container ${active ? 'active' : ''}`}
              style={active ? { transform: 'scale(2)' } : { transform: 'scale(1)' }}
              onClick={() => setGlobal({
                activeImageId: photo.imageId,
                activeCluster: []
              })}
            >
              {showOrientation !== false && <img
                className='orientation'
                src={require('images/map-direction-0.png')}
                style={{ transform: `rotate(${photo.heading}deg)` }}
              />}
              <img className='pin' src={require('images/mapcamera_red.png')}/>
            </div>
          </Marker>
        );
      }

      let active = false;      
      try {
        active = activeImage &&
          item.points.filter(p => p.id === activeImage.imageId).length > 0
        if ( active ) {
          const newActiveCluster = item.points.map(p => p.id);
          setTimeout(() => {
            // check for a change, otherwise it's endless loop (of render cycles)
            if (activeCluster.sort().toString() != newActiveCluster.sort().toString() ) {
              setGlobal({ activeCluster: newActiveCluster })
            }
          }, 100);
        }
      } catch (e) {
        console.log(e.name);
      }

      return (
        <ClusterMarker
          key={key}
          lat={item.wy}
          lng={item.wx}
        >
          <div
            className="cluster-marker cluster-marker-project"
            onClick={() => {
              setGlobal({
                activeImageId: item.points[0].id,
                activeCluster: item.points.map(p => p.id)
              })
            }}
            style={
              active ?
              { transform: 'scale(1.8)', borderColor: 'gray' }
              :
              { transform: 'scale(1)' }
            }
          >
            {item.numPoints}
          </div>
        </ClusterMarker>
      );
    })}
  </GoogleMapReact>
}

export const ProjectsMap = ({ projects, mapType }) => {
  let photos = [];
  const [ clusters, setClusters ] = useState([])
  const [ currentMapType, setCurrentMapType ] = useState(mapType);

  const [ state, setState ] = useSetState(emptyMapState)
  const { map, maps, renderReady } = state

  for (let project of projects) {
    if ( project.images.length ) {
      let latestPhoto = project.images.slice(-1)[0];
      latestPhoto.project = project.id; // add project id to make it active on click
      latestPhoto.projectName = project.name;
      if (latestPhoto.latitude) {
        photos.push(latestPhoto);
      }
    }
  }

  const getPhotoByID = Object.fromEntries(
    photos.map(p => [p.imageId, p])
  )

  useEffect(() => {
    // load map if render is marked as ready (see first side effect)
    if(renderReady) {
      map.fitBounds(getBounds(photos, maps))
    }
  }, [ renderReady ])

  const handleMapChange = ({ center, zoom, bounds }) => {
    console.log(center, zoom, bounds)
    const markersData = photos
      .map(photo => { return {id: photo.imageId, lat: photo.latitude, lng: photo.longitude}});
    const getClusters = supercluster(markersData, {
      minZoom: 0,
      maxZoom: 21,
      radius: 20,
    });
    if(map) {
      const clusters = getClusters({center, zoom, bounds});
      console.log(clusters);
      setClusters(clusters);
    }  
  }

  return <GoogleMapReact
    bootstrapURLKeys={{ key: 'AIzaSyByRREcA4y26ImKgyRCR3Nkb2wfBDrRx3I' }}
    defaultCenter={{ lat: 39.8283, lng: 98.5795 }}
    defaultZoom={12}
    options={maps => getMapOptions(maps, currentMapType)}
    yesIWantToUseGoogleMapApiInternals
    onGoogleApiLoaded={({ map, maps }) => setState({ map, maps, renderReady: true })}
    onChange={handleMapChange}
    onMapTypeIdChange={(mt) => setCurrentMapType(mt)}
  >
    {clusters.map((item, key) => {
      if (item.numPoints === 1) {
        let photo = getPhotoByID[item.points[0].id];
        const active = undefined;
        return (
          <Marker
            key={key}
            lat={photo.latitude}
            lng={photo.longitude}
          >
            <div className={`project-marker ${active ? 'active' : ''}`}
              style={active ? { transform: 'scale(2)' } : { transform: 'scale(1)' }}
              onClick={() => {
                setGlobal({
                  activeProjectId: photo.project,
                  activeImageId: null
                })
              }}
            >
              <img className='pin' src='/static/images/mappin_red.png'/>
              {photo.projectName}
            </div>
          </Marker>
        );
      }
      
      let active = false;      
      try {
        active = activeImage &&
          item.points.filter(p => p.id === activeImage.imageId).length > 0
      } catch (e) {
        console.log(e.name);
      }

      return (
        <ClusterMarker
          key={key}
          lat={item.wy}
          lng={item.wx}
        >
          <div className="cluster-marker"
            style={
              active ?
              { transform: 'scale(1.8)', borderColor: 'gray' }
              :
              { transform: 'scale(1)' }
            }>
            {item.numPoints}
          </div>
        </ClusterMarker>
      );
    })}
  </GoogleMapReact>
}

const getMapOptions = (maps, type) => {
  return {
    streetViewControl: true,
    scaleControl: true,
    fullscreenControl: false,
    styles: [ {
      featureType: "poi.business",
      elementType: "labels",
      stylers: [ {
        visibility: "off"
      } ]
    } ],
    gestureHandling: "greedy",
    disableDoubleClickZoom: true,

    mapTypeControl: true,
    mapTypeId: !type || type == 'roadmap' ? maps.MapTypeId.ROADMAP : maps.MapTypeId.SATELLITE,
    mapTypeControlOptions: {
      style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: maps.ControlPosition.BOTTOM_CENTER,
      mapTypeIds: [
        maps.MapTypeId.ROADMAP,
        maps.MapTypeId.SATELLITE,
        maps.MapTypeId.HYBRID
      ]
    },

    zoomControl: true,
    clickableIcons: false
  }
}
